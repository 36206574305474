// src/pages/TextSummarizationPage.js
import React, { useState } from "react";
import { summarizeText } from "../services/textsummaryService";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Header from "../components/Header"; // Import the Header
import { useAuth } from "../contexts/AuthContext";

const TextSummarizationPage = () => {
  const navigate = useNavigate(); // Initialize navigate
    const { isAuthenticated, logout } = useAuth(); 


        const [text, setText] = useState("");
        const [summary, setSummary] = useState(null);
        const [error, setError] = useState("");

        const handleSummarize = async () => {
          setError("");
          try {
            const result = await summarizeText(text);
            setSummary(result.summary);
          } catch (err) {
            setError("Error summarizing text. Please try again.");
          }
        };

        return (
         <div>
      {isAuthenticated && (
        <Header isAuthenticated={isAuthenticated} logout={logout} />
      )}

      <div
        style={{
          backgroundColor: "#f0f0f0",
          color: "#0e0e0e",
          padding: "20px",
        }}
      >


            <h1>Text Summarization</h1>
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows="4"
              style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
            />
            <button
              onClick={handleSummarize}
              style={{
                padding: "10px 20px",
            backgroundColor: "#1f7a8c",
                color: "#fff",
              }}
            >
              Summarize
            </button>

            {error && <p style={{ color: "red" }}>{error}</p>}

            {summary && (
              <div>
                <h3>Summary</h3>
                <p>{summary}</p>
              </div>
            )}
            </div>
                        </div>

        );
      };;

export default TextSummarizationPage;
