import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import EntryPage from "../components/EntryPage";
import { useAuth } from "../contexts/AuthContext";

const Mission = () => {
  const [showAuth, setShowAuth] = useState(false);
  const [authView, setAuthView] = useState("login");
  const { isAuthenticated, logout } = useAuth();

  const handleAuthToggle = (view) => {
    setAuthView(view);
    setShowAuth(true);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className.includes("auth-popup-overlay")) {
      setShowAuth(false);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header
        isAuthenticated={isAuthenticated}
        logout={logout}
        onLoginClick={() => handleAuthToggle("login")}
        onSignupClick={() => handleAuthToggle("signup")}
      />

      {/* Auth Modal */}
      {showAuth && (
        <div className="auth-popup-overlay" onClick={handleOverlayClick}>
          <EntryPage
            className="auth-popup"
            initialView={authView}
            onClose={() => setShowAuth(false)}
          />
        </div>
      )}

      <div
        style={{
          padding: "40px",
          maxWidth: "100%",
          margin: "0 auto",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          flexGrow: 1,
        }}
      >
        <h1 style={{ textAlign: "center" }}>Our Mission</h1>
        <hr style={{ marginBottom: "20px" }} />

        <h2 style={{ color: "#00796b" }}>AI Tools for Everyone – 100% Free!</h2>
        <p>
          🚀 Unleash the Power of AI – Absolutely Free!
          <br />
          No subscriptions. No hidden fees. Just pure innovation at your
          fingertips.
        </p>

        <h2 style={{ color: "#00796b" }}>💡 Our AI-Powered Tools</h2>
        <p>
          Experience the future of communication with our AI Tools, powered by
          the cutting-edge Chat GPT-4.0 version!
        </p>

        <ul>
          {[
            "AI Chatbot: A smart chatbot powered by GPT",
            "Free Text-to-Image AI Generator: Create stunning AI-generated images and visuals from your ideas.",
            "AI Video Summarizer: Summarize video transcripts using AI.",
            "Text-to-Image: Create images from text prompts.",
            "AI Avatar Creator: Generate avatars with AI.",
            "Free AI-Powered Text Editor: Enhance writing with AI-driven suggestions, grammar correction, and content generation.",
          ].map((tool, index) => (
            <li key={index}>{tool}</li>
          ))}
        </ul>

        <h2 style={{ color: "#00796b", marginTop: "20px" }}>
          🌟 Why Use Our Free AI Tools?
        </h2>
        <p>
          <strong>No Cost, No Catch:</strong> Full access to all features
          without paying a dime.
          <br />
          <strong>Easy to Use:</strong> No technical skills required – simply
          sign up and start creating.
          <br />
          <strong>Accessible to Everyone:</strong> Perfect for startups,
          freelancers, entrepreneurs, and content creators.
        </p>

        <h2 style={{ color: "#00796b" }}>
          🚀 Please Contribute to Our Project!
        </h2>
        <p>
          We would greatly appreciate your support! By registering as a Premium
          User, your generous donation will help us continue our mission. Thank
          you!
        </p>
      </div>

      <Footer style={{ marginTop: "auto" }} />
    </div>
  );
};

export default Mission;
