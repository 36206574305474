import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const useAuth = () => {
  const context = useContext(AuthContext);

  // Provide default values if context is undefined
  if (!context) {
    return { user: null, loading: true }; // Set loading to true if context is not available
  }

  return context; // Return user and loading from the context
};

export default useAuth;
