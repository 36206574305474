import React, { useState, useCallback } from "react";
import readerService from "../services/readerService"; // Import the service
import { IconButton } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp"; // Import appropriate icon

const AccessibilityReader = React.memo(() => {
  const [reading, setReading] = useState(false);
  console.log("AccessibilityReader rendered");

  // Memoize the read aloud handler
  const handleReadAloud = useCallback(async () => {
    const text = document.body.innerText; // Capture the page content as text
    setReading(true);
    try {
      const audioBlob = await readerService.getSpokenContent(text); // Use service to get audio
      const audio = new Audio(URL.createObjectURL(new Blob([audioBlob])));
      audio.play();
    } catch (error) {
      console.error("Error reading aloud", error);
    } finally {
      setReading(false);
    }
  }, []);

  return (
    <IconButton
      onClick={handleReadAloud}
      disabled={reading}
      aria-label="Read Aloud"
    >
      <VolumeUpIcon sx={{ color: reading ? "gray" : "white" }} />
    </IconButton>
  );
});

export default AccessibilityReader;
