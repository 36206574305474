import React from "react";
import { styled } from "@mui/system";
import {
  Typography,
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQSection = styled("section")({
  padding: "40px 0",
  backgroundColor: "#f8f9fa", // Light background color
});

const FAQContainer = styled(Container)({
  maxWidth: "800px",
    margin: "0 auto",
  
});


const FAQTitle = styled(Typography)({
  textAlign: "center",
  fontSize: "2rem",
  fontWeight: "500",
  marginBottom: "20px",
  color: "#333",
});

const FAQSubtitle = styled(Typography)({
  textAlign: "center",
  fontSize: "1.25rem",
  color: "#666",
  marginBottom: "40px",
});

const FAQAccordion = styled(Accordion)({
  backgroundColor: "#f0f0f0", // Light gray background for each FAQ item
  boxShadow: "none",
  "&:not(:last-child)": {
    marginBottom: "8px",
  },
});

const FAQAccordionSummary = styled(AccordionSummary)({
  fontWeight: "500",
  color: "#333",
});

const FAQAccordionDetails = styled(AccordionDetails)({
  color: "#555",
});

const FAQ = () => {
  const faqItems = [
    {
      question: "How does our AI tool personalize its recommendations?",
      answer:
        "Our AI tool analyzes user behavior and preferences to provide personalized suggestions, improving over time as it learns more.",
    },
    {
      question: "Can all skill levels benefit from using this tool?",
      answer:
        "Yes, our tool is designed for all skill levels, offering adjustable settings and guides for beginners and advanced features for professionals.",
    },
    {
      question: "Does the tool provide real-time support?",
      answer:
        "Absolutely! We offer real-time support through our integrated chat system, where users can get assistance whenever needed.",
    },
  ];

  return (
    <FAQSection>
      <FAQContainer>
        <Box textAlign="center">
          <FAQTitle variant="h4">Frequently Asked Questions</FAQTitle>
          <FAQSubtitle variant="h6">
            Everything you need to know about our product and services. If you
            cannot find the answer, please contact us.
          </FAQSubtitle>
        </Box>
        {faqItems.map((item, index) => (
          <FAQAccordion key={index}>
            <FAQAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{item.question}</Typography>
            </FAQAccordionSummary>
            <FAQAccordionDetails>
              <Typography>{item.answer}</Typography>
            </FAQAccordionDetails>
          </FAQAccordion>
        ))}
      </FAQContainer>
    </FAQSection>
  );
};

export default FAQ;
