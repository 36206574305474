import { useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import DOMPurify from "dompurify";
import { escapeHtml } from "../utils/securityUtils";

const styles = {
  input: {
    width: "90%",
    padding: "10px",
    margin: "10px 0",
    border: "2px solid #1f7a8c",
    borderRadius: "5px",
    color: "#1f7a8c",
    background: "none",
    fontSize: "large",
    fontWeight: "bold",
  },
  button: {
    width: "50%",
    padding: "10px",
    border: "2px solid #1f7a8c",
    borderRadius: "40px",
    background: "transparent",
    color: "#1f7a8c",
    fontWeight: "bolder",
    cursor: "pointer",
    margin: "20px 0",
    transition: "background 0.3s ease, color 0.3s ease",
  },
  buttonHover: {
    background: "#55e6c1",
    color: "#1f7a8c",
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
};



const RegisterForm = ({ onClose }) => {
  const { register } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameError("");
    setEmailError("");
    setPasswordError("");
    setSuccessMessage("");

    if (name.trim() === "") {
      setNameError("Name is required");
    }
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    }
    if (password.trim() === "") {
      setPasswordError("Password is required");
    }

    if (nameError || emailError || passwordError) return;

    try {
      await register({
        name: sanitizeInput(name),
        email: sanitizeInput(email),
        password: sanitizeInput(password),
      });
      setSuccessMessage("Registration successful! You can now log in.");
      onClose(); // Close EntryPage on successful registration
    } catch (error) {
      console.error("Registration failed", error);
      setEmailError(
        error.response?.data?.error || "An unexpected error occurred."
      );
    }
  };

  const sanitizeInput = (input) => DOMPurify.sanitize(escapeHtml(input));

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          style={styles.input}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        {nameError && <div className="error-message">{nameError}</div>}
        <input
          style={styles.input}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        {emailError && <div className="error-message">{emailError}</div>}
        <input
          style={styles.input}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        {passwordError && <div className="error-message">{passwordError}</div>}
        <button style={styles.button} type="submit">
          Register
        </button>
      </form>
      {successMessage && <div>{successMessage}</div>}
    </div>
  );
};

export default RegisterForm;
