import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Paper,
  CssBaseline,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { saveAs } from "file-saver";
import { useVideoSummarization } from "../hooks/useVideoSummarization";
import Header from "../components/Header"; // Import the Header
import { useAuth } from "../contexts/AuthContext"; 


// Create a dark theme using MUI's createTheme
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0bec5",
    },
  },
});

const VideoSummarizationPage = () => {
  const {
    videoUrl,
    setVideoUrl,
    summaryType,
    setSummaryType,
    summary,
    error,
    isLoading,
    handleSummarize,
  } = useVideoSummarization();
  const navigate = useNavigate();
    const { isAuthenticated, logout } = useAuth(); 

  const handleDownload = () => {
    const blob = new Blob([summary], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "summary.txt");
  };



  return (
    <div>
      {isAuthenticated && (
        <Header isAuthenticated={isAuthenticated} logout={logout} />
      )}

      <Paper
        style={{
          padding: "2%",
          maxWidth: "80%",
          margin: "20px auto",
          backgroundColor: "white",
          position: "relative",
          paddingTop: "5%",
        }}
      >
        <Typography variant="h4" color="#1f7a8c" gutterBottom>
          Let's Summarize YouTube Video
        </Typography>

        {/* Input Fields */}
        <TextField
          label="YouTube Video URL"
          variant="outlined"
          fullWidth
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          margin="normal"
          InputLabelProps={{
            style: { color: darkTheme.palette.text.primary },
          }}
        />

        <Select
          value={summaryType}
          onChange={(e) => setSummaryType(e.target.value)}
          fullWidth
          variant="outlined"
          margin="normal"
          style={{
            marginTop: "10px",
            color: darkTheme.palette.text.primary,
            backgroundColor: darkTheme.palette.background.paper,
          }}
        >
          <MenuItem value="Detailed">Detailed</MenuItem>
          <MenuItem value="Short">Short</MenuItem>
        </Select>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSummarize}
          disabled={isLoading} // Disable the button while loading
          style={{ marginTop: "20px" }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Summarize"}
        </Button>

        {/* Loading message */}
        {isLoading && (
          <Typography
            variant="body2"
            color="secondary"
            style={{ marginTop: "10px" }}
          >
            We are working on your request. Please wait...
          </Typography>
        )}

        {/* Error message */}
        {error && (
          <Typography
            variant="body2"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {error}
          </Typography>
        )}

        {/* Display summary and download button */}
        {summary && (
          <div>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Summary
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
              {summary}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDownload}
              style={{ marginTop: "10px" }}
            >
              Download Summary
            </Button>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default VideoSummarizationPage;
