import React, { useState, useEffect } from "react";
import "../styles/EntryPage.css";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const EntryPage = ({ onClose, initialView = "login" }) => {
  const [currentView, setCurrentView] = useState(initialView);

  useEffect(() => {
    setCurrentView(initialView);
  }, [initialView]);

  const handleToggle = (view) => {
    setCurrentView(view);
  };

  return (
    <div className="entry-popup">
      <div className="tabs-form">
        <button
          className={`tab-button ${currentView === "login" ? "active" : ""}`}
          onClick={() => handleToggle("login")}
        >
          Log In
        </button>
        <button
          className={`tab-button ${currentView === "register" ? "active" : ""}`}
          onClick={() => handleToggle("register")}
        >
          Register
        </button>
      </div>
      <div className="form-container">
        {currentView === "login" ? (
          <LoginForm onClose={onClose} />
        ) : (
          <RegisterForm onClose={onClose} />
        )}
      </div>
      <button className="close-button" onClick={onClose}>
        Close
      </button>
    </div>
  );
};

export default EntryPage;
