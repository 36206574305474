import React, { useState } from "react";
import { Container, Typography, Box, Tabs, Tab } from "@mui/material";
import Header from "../components/Header";
import RotateImage from "../components/imagecomponents/Rotate";
import CropImage from "../components/imagecomponents/Crop";
import ChangeFileType from "../components/imagecomponents/ImageConverter";
import "../styles/ImageService.css";
import ImageServiceSectionMotion from "../components/ImageServiceSectionMotion";
import { useNavigate } from "react-router-dom";
import EntryPage from "../components/EntryPage";
import { useAuth } from "../contexts/AuthContext";

const ImageService = () => {
  const [selectedService, setSelectedService] = useState(0);
const [showAuth, setShowAuth] = useState(false);
const [authView, setAuthView] = useState("login");
const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();
  

const handleAuthToggle = (view) => {
  setAuthView(view);
  setShowAuth(true);
};

const handleOverlayClick = (e) => {
  if (e.target.classList.contains("auth-popup-overlay")) {
    setShowAuth(false);
  }
};

  const handleChange = (event, newValue) => {
    setSelectedService(newValue);
  };

  const serviceComponents = [
    <RotateImage key="rotate" />,
    <CropImage key="crop" />,
    <ChangeFileType key="convert" />,
  ];

  return (
    <div className="image-service">
      <Header
        isAuthenticated={isAuthenticated}
        logout={logout}
        onLoginClick={() => handleAuthToggle("login")}
        onSignupClick={() => handleAuthToggle("signup")}
      />
      {/* Auth Modal */}
      {showAuth && (
        <div className="auth-popup-overlay" onClick={handleOverlayClick}>
          <EntryPage
            className="auth-popup"
            initialView={authView}
            onClose={() => setShowAuth(false)}
          />
        </div>
      )}

      <header className="styled-header">
        <Container>
          <Tabs
            value={selectedService}
            onChange={handleChange}
            aria-label="image services"
            className="service-tabs"
          >
            <Tab label="Rotate Image" className="service-tab" />
            <Tab label="Crop Image" className="service-tab" />
            <Tab label="Change File Type" className="service-tab" />
          </Tabs>
        </Container>
      </header>

      <Container>
        <Box className="content-box">
          <Typography variant="h4" component="h1" gutterBottom>
            AI Image Services
          </Typography>
          <Typography variant="body1" paragraph>
            Your free image processing journey with us.
          </Typography>
          {serviceComponents[selectedService]}
        </Box>
      </Container>
      <div>
        <ImageServiceSectionMotion />
      </div>
    </div>
  );
};

export default ImageService;
