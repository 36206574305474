import React, { useState } from "react";
import { extractKeywords } from "../services/keywordExtractionService";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header"; // Import the Header
import { useAuth } from "../contexts/AuthContext"; 


const KeywordExtractionPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth(); 

  const [text, setText] = useState("");
  const [keywords, setKeywords] = useState(null);
  const [error, setError] = useState("");

  const handleExtract = async () => {
    setError("");
    try {
      const data = await extractKeywords(text);
      setKeywords(data.keywords);
    } catch (err) {
      setError("Error extracting keywords. Please try again.");
    }
  };

  return (
    <div>
      {isAuthenticated && (
        <Header isAuthenticated={isAuthenticated} logout={logout} />
      )}

      <div
        style={{
          backgroundColor: "#f0f0f0",
          color: "#0e0e0e",
          padding: "20px",
        }}
      >
        <h1>Keyword Extraction</h1>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          rows="6"
          style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
        />
        <button
          onClick={handleExtract}
          style={{
            padding: "10px 20px",
            backgroundColor: "#1f7a8c",
            color: "#fff",
          }}
        >
          Extract Keywords
        </button>

        {error && <p style={{ color: "red" }}>{error}</p>}
        {keywords && (
          <div>
            <h3>Extracted Keywords</h3>
            <p>{keywords}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default KeywordExtractionPage;
