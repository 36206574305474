// src/components/ImageServiceSection.js
import React, { useState, useRef } from "react";
import BeforeAfterSlider from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import "../styles/ImageServiceSection.css";
import beforeRotate from "../images/maple-tree-up.jpg";
import afterRotate from "../images/rotated-image.png";
import beforeCrop from "../images/beforecropped.jpg";
import cropedImage from "../images/cropped-image.png";
import  afterprompt from "../images/prompt.png";
import befortextimage from "../images/propmt-after.jpg";

const imageData = [
  {
    id: 1,
     title: "Rotate ",
    beforeImage: beforeRotate,
    afterImage: afterRotate,
  },
  {
    id: 2,
    title: "Crop",
    beforeImage:beforeCrop,
    afterImage:cropedImage,
    
  },
  {
    id: 3,
    title: "Text To Image",
    beforeImage:befortextimage,
    afterImage:afterprompt,
  }
];

const ImageServiceSectionMotion = () => {
  return (
    <div className="image-service-section">
      <h1>Experience Image Transformations</h1>
      <p className="section-description">
        Explore our cutting-edge AI-powered image services.
      </p>
      <div className="card-container">
        {imageData.map((image) => (
          <div key={image.id} className="card-wrapper">
            <ImageCard image={image} />
            {/* Display title below each card */}
            <div className="image-title">{image.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Card Component
const ImageCard = ({ image }) => {
  const [currentPercentPosition, setCurrentPercentPosition] = useState(50);
  const sliderRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!sliderRef.current) return;

    const sliderRect = sliderRef.current.getBoundingClientRect();
    const offsetX = e.clientX - sliderRect.left;
    const width = sliderRect.width;

    const newPosition = (offsetX / width) * 100;
    setCurrentPercentPosition(newPosition);
  };

  const beforeOpacity = 1 - currentPercentPosition / 100;
  const afterOpacity = currentPercentPosition / 100;

  return (
    <div className="card" onMouseMove={handleMouseMove} ref={sliderRef}>
      <div className="slider-container">
        <BeforeAfterSlider
          firstImage={{
            imageUrl: image.beforeImage,
            alt: "Before",
          }}
          secondImage={{
            imageUrl: image.afterImage,
            alt: "After",
          }}
          width={300}
          height={200}
          currentPercentPosition={currentPercentPosition}
          onChangePercentPosition={setCurrentPercentPosition}
        />
        <div
          className="before-title caption"
          style={{ opacity: beforeOpacity }}
        >
          Before
        </div>
        <div className="after-title caption" style={{ opacity: afterOpacity }}>
          After
        </div>
      </div>
    </div>
  );
};

export default ImageServiceSectionMotion;