import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import DOMPurify from "dompurify";
import { escapeHtml } from "../utils/securityUtils";

const styles = {
  input: {
    width: "90%",
    padding: "10px",
    margin: "10px 0",
    border: "2px solid #1f7a8c",
    borderRadius: "5px",
    color: "#1f7a8c",
    background: "none",
    fontSize: "large",
    fontWeight: "bold",
  },
  button: {
    width: "50%",
    padding: "10px",
    border: "2px solid #1f7a8c",
    borderRadius: "40px",
    background: "transparent",
    color: "#1f7a8c",
    fontWeight: "bolder",
    cursor: "pointer",
    margin: "20px 0",
    transition: "background 0.3s ease, color 0.3s ease",
  },
  buttonHover: {
    background: "#1f7a8c",
    color: "#182c61",
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = ({ onClose }) => {
  const { login } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await login({
          email: sanitizeInput(values.email),
          password: sanitizeInput(values.password),
        });
        onClose();
      } catch (error) {
        console.error("Login failed", error);
        setErrorMessage(
          error.response?.data?.error || "An unexpected error occurred."
        );
      }
    },
  });

  const sanitizeInput = (input) => DOMPurify.sanitize(escapeHtml(input));

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <input
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Email"
          style={styles.input}
        />
        {formik.touched.email && formik.errors.email && (
          <div style={styles.errorMessage}>{formik.errors.email}</div>
        )}
        <input
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Password"
          style={styles.input}
        />
        {formik.touched.password && formik.errors.password && (
          <div style={styles.errorMessage}>{formik.errors.password}</div>
        )}
        {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
        <button
          type="submit"
          style={styles.button}
          onMouseOver={(e) => Object.assign(e.target.style, styles.buttonHover)}
          onMouseOut={(e) => Object.assign(e.target.style, styles.button)}
        >
          Login
        </button>
      </form>
      <Link to="/forgot-password">Forgot your password?</Link>
    </div>
  );
};

export default LoginForm;
