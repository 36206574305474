import React, { useState } from "react";
import { generateSpeech } from "../services/texttoSpeechServices";
import Header from "../components/Header";
import { useAuth } from "../contexts/AuthContext";
import "../styles/TextToSpeechPage.css";


const TextToSpeechPage = () => {
  const [text, setText] = useState("");
  const [voice, setVoice] = useState("alloy");
  const [audioSrc, setAudioSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const model = "tts-1-hd";
  const { isAuthenticated, logout } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const audioBase64 = await generateSpeech(text, model, voice);
      const binaryString = atob(audioBase64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const audioBlob = new Blob([bytes], { type: "audio/mp3" });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioSrc(audioUrl);
    } catch (error) {
      console.error("Error generating speech:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {isAuthenticated && (
        <Header isAuthenticated={isAuthenticated} logout={logout} />
      )}

      <div className="text-to-speech-page">
        <div className="content">
          <h1>Text-to-Speech Generator</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-control">
              <label>Enter text:</label>
              <textarea
                rows="4"
                value={text}
                onChange={(e) => setText(e.target.value)}
                required
              />
            </div>

            <div className="form-control">
              <label>Select Voice:</label>
              <select value={voice} onChange={(e) => setVoice(e.target.value)}>
                <option value="alloy">Alloy</option>
                <option value="echo">Echo</option>
                <option value="fable">Fable</option>
                <option value="onyx">Onyx</option>
                <option value="nova">Nova</option>
                <option value="shimmer">Shimmer</option>
              </select>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="generate-button"
            >
              {loading ? "Generating..." : "Generate Speech"}
            </button>
          </form>

          {audioSrc && (
            <div className="audio-player">
              <h2>Generated Speech</h2>
              <audio controls src={audioSrc}></audio>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextToSpeechPage;
