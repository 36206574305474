import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "../styles/ImageGenerator.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import image1 from "../images/pexels-cottonbro-7523254.jpg";
import image2 from "../images/pexels-googledeepmind-17485657.jpg";
import image3 from "../images/pexels-mo-eid-1268975-11798029.jpg";
import image4 from "../images/pexels-nivedita-singh-1246564-2443072.jpg";
import image5 from "../images/pexels-npandey-2446439.jpg";
import image6 from "../images/pexels-philippedonn-1133957.jpg";
import image7 from "../images/pexels-pixabay-459301.jpg";
import image8 from "../images/pexels-pixabay-68507.jpg";
import image9 from "../images/pexels-skitterphoto-42415.jpg";
import Header from "../components/Header";
import { useAuth } from "../contexts/AuthContext";

const ImageGenerator = () => {
  const [prompt, setPrompt] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/get-csrf-token`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await response.json();
        setCsrfToken(data.csrfToken);
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleGenerateImage = async () => {
    if (!prompt) {
      alert("Please enter a prompt!");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/images/generate`,
        { prompt },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      );

      if (response.data.imageUrl) {
        setImageUrl(response.data.imageUrl);
      }
    } catch (error) {
      console.error("Error generating image:", error);
      alert("Failed to generate image");
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate("/");
  };

  const cardItems = [
    { id: 1, image: image1 },
    { id: 2, image: image2 },
    { id: 3, image: image3 },
    { id: 4, image: image4 },
    { id: 5, image: image5 },
    { id: 6, image: image6 },
    { id: 7, image: image7 },
    { id: 8, image: image8 },
    { id: 9, image: image9 },
  ];

  return (
    <div>
      {isAuthenticated && (
        <Header isAuthenticated={isAuthenticated} logout={logout} />
      )}
      <div className={styles.mainContainer}>
        <div className={styles.imageGeneratorContainer}>
          <div className={styles.imageGeneratorBox}>
            <div className={styles.overlay}></div>
  
            <h2 className={styles.title} style={{ marginTop: "60px" }}>
              AI Image Generator
            </h2>
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter a prompt"
              className={styles.promptInput}
              disabled={loading}
            />
            <button
              type="submit"
              disabled={loading}
              onClick={handleGenerateImage}
              className={styles.generateButton}
            >
              {loading ? "Generating..." : "Generate Image"}
            </button>
            {imageUrl && (
              <div className={styles.imageDisplay}>
                <h2 className={styles.imageTitle}>Generated Image:</h2>
                <img
                  src={imageUrl}
                  alt="Generated"
                  className={styles.generatedImage}
                />
                <button
                  className={styles.downloadButton}
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = imageUrl;
                    link.download = "generated-image.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  Download Image
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.cardContainerg}>
          {cardItems.map((item) => (
            <div key={item.id} className={styles.card}>
              <img
                src={item.image}
                alt={`Image ${item.id}`}
                className={styles.cardImage}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGenerator;
