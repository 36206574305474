import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import "../styles/Dashboard.css";
import Header from "../components/Header";
import Card from "../components/Card";
import "../styles/Dashboard.css";
import { useNavigate } from "react-router-dom";
import EntryPage from "../components/EntryPage";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ImageServiceSectionMotion from "../components/ImageServiceSectionMotion";
import { motion } from "framer-motion";
import Typed from "typed.js"; // Import Typed.js
import Footer from "../components/Footer";
import audiopiano from "../components/audio/relaxing-guitar.mp3";
import TextVideo from "../images/text-video.mp4";
import FAQ from "../components/Faqcomponent";

const pricingPlans = [
  {
    name: "Welcome",
    price: "FREE",
    features: [
      "50 AI text generations",
      "Basic image generation",
      "Limited content summarization",
      "Basic chat interactions",
    ],
  },
  {
    name: "Basic",
    price: "$10/month",
    features: [
      "500 AI text generations",
      "10 image generation",
      "15 content summarization",
      "30 chat interactions",
    ],
  },
  {
    name: "Premium",
    price: "$30/month",
    features: [
      "Unlimited AI text generations",
      "Advanced image generation",
      "Unlimited content summarization",
      "Enhanced chat features",
      "AI-powered avatar interactions",
    ],
  },
  {
    name: "Enterprise",
    price: "Contact us",
    features: [
      "Custom AI solutions",
      "Dedicated support",
      "Priority access to new features",
      "Full suite of AI tools",
      "Advanced security features",
    ],
  },
];

const LandingPage = () => {
  const [showAuth, setShowAuth] = useState(false);
  const [authView, setAuthView] = useState("login");
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();
  const typedRef = useRef(null);
  const videoRef = useRef(null); // Create a ref for the video

  const handleAuthToggle = (view) => {
    setAuthView(view);
    setShowAuth(true);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("auth-popup-overlay")) {
      setShowAuth(false);
    }
  };

  useEffect(() => {
    if (typedRef.current) {
      const options = {
        strings: [
          "AI Assistant",
          "Your AI Companion",
          "convert text to images",
          "Turn Text to Voice",
          "Summarize Text",
          "Convert Videos into Text",
        ],
        typeSpeed: 50,
        backSpeed: 30,
        loop: true,
      };

      const typed = new Typed(typedRef.current, options); // Initialize Typed.js

      return () => {
        typed.destroy(); // Cleanup on unmount
      };
    }
    if (videoRef.current) {
      // Attempt to play the video
      videoRef.current.play().catch((error) => {
        console.log("Autoplay was prevented:", error);
      });
    }
  }, []);
   return (
     <div className="landing-page">
       <Header
         isAuthenticated={isAuthenticated}
         logout={logout}
         onLoginClick={() => handleAuthToggle("login")}
         onSignupClick={() => handleAuthToggle("signup")}
       />
       {/* Auth Modal */}
       {showAuth && (
         <div className="auth-popup-overlay" onClick={handleOverlayClick}>
           <EntryPage
             className="auth-popup"
             initialView={authView}
             onClose={() => setShowAuth(false)}
           />
         </div>
       )}
       {/* Hero Section */}
       {/* <section className="hero-section">
        <motion.div
          className="hero-content"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="hero-title">AI-Powered Assistant for the Future</h2>
          <p className="hero-subtitle">
            Enhance your workflow with the power of AI and automation.
          </p>

          <div className="sqs-block-content">
            <h2 style={{ fontWeight: "bold" }}>
              Gen-Z Assistant:
              <span ref={typedRef} className="typed-words"></span>
              <span className="typed-cursor" aria-hidden="true">
                |
              </span>
            </h2>
          </div>
        </motion.div>
      </section> */}
       <div>
         <ImageServiceSectionMotion />
       </div>
       {/* Music Section */}
       <section className="music-section ">
         <div className="music-content">
           <div className="music-text">
             <h3 className="music-title">
               Create Music with Our GenZ AI Audio
             </h3>
             <p className="music-description">
               Create music, sound effects, and soundscapes with text-to-audio.
               Describe what you want with text and hear it come to life.
             </p>
           </div>
           <div className="iframe-container">
             <audio controls>
               <source src={audiopiano} type="audio/mp3" />
               Your browser does not support the audio element.
             </audio>
           </div>
         </div>
       </section>
       <section className="video-section ">
         <div className="video-grid-container">
           <div className="block-v title-block-v">
             <h3>
               <strong>Animate with Our AI-Powered Video Tool</strong>
             </h3>
             <p>
               Create videos with text or image, turning your concepts into
               captivating cinematics.
             </p>
           </div>
           <div className="block-v video-block">
             {/* Attach the ref to the video element */}
             <video
               className="native-video"
               ref={videoRef}
               muted
               autoPlay
               loop
               playsInline
               onCanPlay={() => videoRef.current.play()} // Ensure the video plays when it's ready
             >
               <source src={TextVideo} type="video/mp4" />
               Your browser does not support the video tag.
             </video>
           </div>
         </div>
       </section>

       {/* Pricing Section */}
       <section className="pricing-section">
         <h2 className="section-title">Pricing</h2>
         <p>Flexible pricing plans for everyone.</p>
         <div className="pricing-container ">
           {pricingPlans.map((plan, index) => (
             <div key={index} className="pricing-card ">
               <h3 className="plan-name">{plan.name}</h3>
               <p className="plan-price">{plan.price}</p>
               <ul className="plan-features">
                 {plan.features.map((feature, i) => (
                   <li key={i}>
                     <span></span>
                     {feature}
                   </li>
                 ))}
               </ul>
               <Link to="/choose-plan" className="try-now-button ">
                 {plan.name === "Enterprise" ? "Contact Us" : "Choose Plan"}
               </Link>
             </div>
           ))}
         </div>
       </section>
       <section className="faqsections">
         <FAQ />
       </section>

       <Footer />
     </div>
   );
};

export default LandingPage;
