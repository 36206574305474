import React, { useEffect } from "react"; // Ensure you import useEffect
import { Routes, Route, useLocation, Router} from "react-router-dom"; // Remove Router import
import axios from "axios";
import { AuthProvider } from "./contexts/AuthContext";
import useAuth from "./hooks/useAuth";
import ChatComponent from "./components/ChatComponent";
import Imageservice from "./pages/ImageService";
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotPassword from "./pages/ForgotPassword";
import CheckEmail from "./pages/CheckEmail";
import VerifyEmailPage from "./pages/Emailverifypage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ErrorPage from "./components/ErrorPage";
import VideoSummarizationPage from "./pages/VideoSummarizationPage";
import TextToImagePage from "./pages/TextToImagePage";
import TextProcessingPage from "./pages/TextProcessingPage";
import ImageGenerator from "./pages/ImageGenerator";
import SentimentAnalysisPage from "./pages/SentimentAnalysisPage";
import TextSummarizationPage from "./pages/TextSummarizationPage";
import KeywordExtractionPage from "./pages/KeywordExtractionPage";
import MathTeacherAssistantPage from "./pages/MathTeacherAssistantPage";
import TextToSpeechPage from "./pages/TextToSpeechPage";
import AudiobookGenerator from "./components/AudiobookGenerator";
import AccessibilityReader from "./components/AccessibilityReader";
import InterviewPrep from "./components/InterviewPrep";
import routes from "./routes";
import LandingPage from "./pages/Dashboard";
import Mission from "./pages/mission";

// index.js or App.js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json";

export default function App() {
  const { pathname } = useLocation();
  const { user, loading } = useAuth(); // Get user and loading state

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });

  return (
    <AuthProvider>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<LandingPage />} />
             <Route path="/mission" element={<Mission />} />
        <Route path="/blog" element={<Mission />} />
        <Route
          path="/interviewPrep"
          element={
            <ProtectedRoute>
              <InterviewPrep userId={user?.id} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chatgpt"
          element={<ProtectedRoute element={ChatComponent} />}
        />
        <Route
          path="/imageservice"
          element={<ProtectedRoute element={Imageservice} />}
        />
        <Route
          path="/summarize"
          element={<ProtectedRoute element={VideoSummarizationPage} />}
        />
        <Route
          path="/generate-image"
          element={<ProtectedRoute element={TextToImagePage} />}
        />
        <Route
          path="/text-summary"
          element={<ProtectedRoute element={TextProcessingPage} />}
        />
        <Route
          path="/ai-image-generator"
          element={<ProtectedRoute element={ImageGenerator} />}
        />
        <Route
          path="/ai-sentiment"
          element={<ProtectedRoute element={SentimentAnalysisPage} />}
        />
        <Route
          path="/ai-textSummary"
          element={<ProtectedRoute element={TextSummarizationPage} />}
        />
        <Route
          path="/keyword-extraction"
          element={<ProtectedRoute element={KeywordExtractionPage} />}
        />
        <Route
          path="/ask-math-teacher"
          element={<ProtectedRoute element={MathTeacherAssistantPage} />}
        />
        <Route
          path="/text-to-speech"
          element={<ProtectedRoute element={TextToSpeechPage} />}
        />
        <Route
          path="/generate-audiobook"
          element={<ProtectedRoute element={AudiobookGenerator} />}
        />
        <Route
          path="/interview-prep"
          element={<ProtectedRoute element={InterviewPrep} />}
        />
        <Route path="/mission" element={<Mission />} />
        <Route path="/blog" element={<Mission />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/check-email" element={<CheckEmail />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/read-text" element={<AccessibilityReader />} />
      </Routes>
    </AuthProvider>
  );
}
