import React from "react";
import { Box, Typography, IconButton, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", 
          mt: 2,
          width: "100%", 
        }}
      >
        
        <Box sx={{ display: "flex", gap: 1, flexGrow: 1 }}>
          <IconButton
            href="https://www.facebook.com/YourPage"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            aria-label="Facebook"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/YourPage"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            aria-label="Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </IconButton>
          <IconButton
            href="https://www.tiktok.com/@YourPage"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            aria-label="TikTok"
          >
            <FontAwesomeIcon icon={faTiktok} />
          </IconButton>
          <IconButton
            href="https://www.twitter.com/YourPage"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            aria-label="Twitter"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </IconButton>
          <IconButton
            href="https://www.linkedin.com/company/YourPage"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            aria-label="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </IconButton>
        </Box>

        
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" component="p">
            &copy; Terms of Service
          </Typography>
          <Typography variant="body2" component="p">
            &copy; Privacy Policy
          </Typography>
          <Typography variant="body2" component="p">
            &copy; 2024 AITOOLS4ALL All rights reserved
          </Typography>
        </Stack>
      </Box>
    </footer>
  );
};

export default Footer;
