import React, { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Drawer, IconButton } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import useChat from "../hooks/useChat";
import DOMPurify from "dompurify";
import { escapeHtml } from "../utils/securityUtils";
import imgsend from "../images/sent.png";
import { useNavigate } from "react-router-dom";
import he from "he"; // HTML entities decoding library
import { CopyToClipboard } from "react-copy-to-clipboard";
import "../styles/Chatgpt.css"; // Import the CSS file
import Header from "./Header"; 
import { useAuth } from "../contexts/AuthContext";

function ChatComponent() {
  const { response, handleSendMessage } = useChat();
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const chatMessagesRef = useRef(null);
  const { isAuthenticated, logout } = useAuth(); 


  const [originalMessages, setOriginalMessages] = useState({});

  const sendMessage = async () => {
    const sanitizedMessage = sanitizeMessage(userMessage);

    if (sanitizedMessage.trim() !== "") {
      const newMessages = [
        ...messages,
        { sender: "You", message: sanitizedMessage },
      ];
      setMessages(newMessages);
      setUserMessage("");

      const messageId = newMessages.length - 1;
      setOriginalMessages((prev) => ({
        ...prev,
        [messageId]: sanitizedMessage,
      }));

      try {
        await handleSendMessage(sanitizedMessage);
      } catch (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "AI 4.O",
            message: "An error occurred. Please try again later.",
          },
        ]);
      }
    }
  };

  const regenerateResponse = async (messageId) => {
    const originalMessage = originalMessages[messageId];

    if (originalMessage) {
      try {
        await handleSendMessage(originalMessage);
      } catch (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "AI 4.O",
            message:
              "An error occurred while regenerating. Please try again later.",
          },
        ]);
      }
    }
  };

  const sanitizeMessage = (message) => {
    return DOMPurify.sanitize(message);
  };

  const decodeHtmlEntities = (html) => {
    if (typeof html !== "string") {
      console.error("decodeHtmlEntities expected a string but got:", html);
      return html;
    }
    return he.decode(html);
  };

  useEffect(() => {
    if (response) {
      const decodedResponse = decodeHtmlEntities(
        response.message ? response.message : response
      );
      const sanitizedResponse = sanitizeMessage(decodedResponse);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "AI 4.O", message: sanitizedResponse },
      ]);
    }
  }, [response]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

useEffect(() => {
  if (chatMessagesRef.current) {
    chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
  }
}, [messages]);


  const handleTextToSpeech = (text) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      speechSynthesis.speak(utterance);
    } else {
      console.error("Text-to-speech is not supported in this browser.");
    }
  };

  const handleFeedback = (type, message) => {
    console.log(`${type} feedback for message:`, message);
  };

  const isCodeBlock = (message) => {
    return message.startsWith("```") && message.endsWith("```");
  };

  return (
    <div>
      {isAuthenticated && (
        <Header isAuthenticated={isAuthenticated} logout={logout} />
      )}
      <div >

        <div className="chat-container">
         
          <div className="chat-messages" ref={chatMessagesRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${
                  msg.sender === "You" ? "user" : "ai"
                }`}
              >
                {isCodeBlock(msg.message) ? (
                  <div className="code-block">
                    {DOMPurify.sanitize(escapeHtml(msg.message))}
                  </div>
                ) : (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(escapeHtml(msg.message)),
                    }}
                  />
                )}
                {msg.sender === "AI 4.O" && (
                  <div className="message-actions">
                    <IconButton
                      onClick={() => handleTextToSpeech(msg.message)}
                      className="action-button"
                    >
                      <VolumeUpIcon />
                    </IconButton>
                    <CopyToClipboard text={msg.message}>
                      <IconButton className="action-button">
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                    <IconButton
                      onClick={() => handleFeedback("good", msg.message)}
                      className="action-button"
                    >
                      <ThumbUpIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleFeedback("bad", msg.message)}
                      className="action-button"
                    >
                      <ThumbDownIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => regenerateResponse(index)}
                      className="action-button"
                    >
                      <RefreshIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="chat-input-container">
            <TextField
              className="chat-input"
              variant="outlined"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your message..."
            />
            <Button
              className="send-button"
              onClick={sendMessage}
              disabled={!userMessage.trim()}
            >
              <img src={imgsend} alt="Send" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatComponent;
