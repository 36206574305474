import React from "react";
import MathTeacherForm from "../components/MathTeacherForm";
import Header from "../components/Header";
import { useAuth } from "../contexts/AuthContext";

const MathTeacherAssistantPage = () => {
  const { isAuthenticated, logout } = useAuth(); 

  return (
    <div>
      {isAuthenticated && (
        <Header isAuthenticated={isAuthenticated} logout={logout} />
      )}

      <MathTeacherForm />
    </div>
  );
};

export default MathTeacherAssistantPage;
