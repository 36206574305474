import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { Button, Box, Menu, MenuItem, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessibilityReader from "./AccessibilityReader";
import useAuth from "../hooks/useAuth";

const HeaderContainer = styled("header")({
  background: "#101418", 
  padding: "20px",
  // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)", 
  position: "sticky",
  top: 0,
  zIndex: 1000,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // borderBottom: "2px solid rgb(92, 39, 212)",
});

const Logo = styled(Typography)({
  fontWeight: "bold",
  color: "hsla(0, 0%, 100%, 0.95)",
  cursor: "pointer",
  "&:hover": {
    color: "#1f7a8c",
  },
});

const CustomButton = styled(Button)({
  color: "hsla(0, 0%, 100%, 0.9)",
  display: "flex",
  alignItems: "center",
  textTransform: "bold",
  "&:hover": {
    color: "#1f7a8c", 
  },
});

const CustomMenuItem = styled(MenuItem)({
  color: "#101418",
  // backgroundColor: "#101418",
  "&:hover": {
    backgroundColor: "#1f7a8c",
    color: "#fff",
  },
});

const Header = React.memo(
  ({ onLoginClick, onSignupClick, isAuthenticated, logout }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const navigate = useNavigate();

    const categories = [
      {
        title: "Image Tools",
        features: [
          { title: "Edit Images", link: "/imageservice" },
          { title: "AI Image Generator", link: "/ai-image-generator" },
        ],
      },
      {
        title: "AI Tools",
        features: [
          { title: "AI Keyword Tool", link: "/keyword-extraction" },
          { title: "AI Chat", link: "/chatgpt" },
          { title: "AI Text Summary", link: "/ai-textSummary" },
        ],
      },
      {
        title: "Video Tools",
        features: [
          { title: "Video Summarization", link: "/summarize" },
          { title: "Math Teacher", link: "/ask-math-teacher" },
        ],
      },
      {
        title: "Text To Audio",
        features: [
          { title: "Convert Text to Audio", link: "/text-to-speech" },
          { title: "Interview Prep", link: "/interview-prep" },
          { title: "Generate Audiobooks", link: "/generate-audiobook" },
        ],
      },
      {
        title: "About Us",
        features: [
          { title: "Our Mission", link: "/mission" },
          { title: "Blog", link: "/blog" },
        ],
      },
    ];

    const handleMenuOpen = (index) => (event) => {
      setAnchorEl(event.currentTarget);
      setOpenMenuIndex(index);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setOpenMenuIndex(null);
    };

  const handleFeatureClick = (featureLink) => () => {
    const publicLinks = ["/mission", "/blog"];

    // Skip authentication check for public links
    if (publicLinks.includes(featureLink) || isAuthenticated) {
      navigate(featureLink);
    } else {
      onLoginClick();
    }
    handleMenuClose();
  };


    return (
      <HeaderContainer>
        <Logo variant="h5" onClick={() => navigate("/")}>
          AI For Gen Z
        </Logo>
        <Box display="flex" alignItems="center">
          <AccessibilityReader />
        </Box>
        <Box display="flex" alignItems="center">
          {categories.map((category, index) => (
            <div key={category.title}>
              <CustomButton onClick={handleMenuOpen(index)}>
                {category.title}
                {category.features.length > 0 && (
                  <ExpandMoreIcon sx={{ marginLeft: 0.5 }} />
                )}
              </CustomButton>
              <Menu
                anchorEl={openMenuIndex === index ? anchorEl : null}
                open={openMenuIndex === index}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {category.features.map((feature) => (
                  <CustomMenuItem
                    key={feature.title}
                    onClick={handleFeatureClick(feature.link)}
                  >
                    {feature.title}
                  </CustomMenuItem>
                ))}
              </Menu>
            </div>
          ))}
          {isAuthenticated ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#1f7a8c",
                "&:hover": { backgroundColor: "#145c6b" },
                marginLeft: 2,
              }}
              onClick={logout}
            >
              Logout
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1f7a8c",
                  "&:hover": { backgroundColor: "#145c6b" },
                }}
                onClick={onLoginClick}
              >
                Login
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#145c6b",
                  "&:hover": { backgroundColor: "#0c404f" },
                  marginLeft: 1,
                }}
                onClick={onSignupClick}
              >
                Signup
              </Button>
            </>
          )}
        </Box>
      </HeaderContainer>
    );
  }
);

export default Header;
